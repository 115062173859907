<template>
	<div class="loaded">
		<b-container class="content intro" fluid>
			<b-row class="maw-lg">
				<b-col xl="3" class="d-none d-xl-block order-2">
					<client-logo square class="float-right" />
				</b-col>
				<b-col xl="9" class="maw-lg">
					<div>
						<h1 class="mb-3">Thank you for making your report.</h1>
						<client-logo class="d-xl-none mb-3 p-0" />
						<h4 class="mb-4 mb-md-0">Your report has been submitted via the <strong>{{orgName}} {{term}}</strong> service, managed by PKF Integrity.<br><br> <template v-if="!$store.state.savedWithoutPassword">For your security you have now been logged out. If you wish to return to the report or check back on how the matter has progressed at a later date, you can log back in using your Report ID and password.</template></h4>
					</div>
				</b-col>
			</b-row>
		</b-container>
		<b-container class="content body" fluid>
			<b-row>
				<b-col>
					<b-button v-if="!$store.state.savedWithoutPassword" :to="'/login?'+org.code" class="mb-5">Login</b-button>
					<h2>What happens next?</h2>
					<p>
						Your matter is reviewed by PKF Integrity and escalated to the correct person within {{orgName}}.
					</p>
					<p v-if="hasChat">
						You can log back in at any time, to go to your private message board where you can provide additional information, check on the progress of the report and chat directly with either PKF Integrity or {{orgName}}.
					</p>
					<p v-else-if="!$store.state.savedWithoutPassword">
						You can log back in at any time, to go to your private message board where you can provide additional information and check on the progress of the report.
					</p>
					<template v-if="$store.state.org.code != 'ARIA'">
						<h2 class="mt-5">A message from {{orgName}}</h2>
						<p>Under the Telecommunications Act 1997, {{orgName}} may contract with its customers either on an individual basis, or by way of a standard form of agreement. {{orgName}} has elected to supply some of its services by way of a standard form.</p>
					</template>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
import { canChat } from "@/mixins";
export default {
	name: 'Thanks',
	mixins: [canChat],
	mounted() {
		this.$store.dispatch('login/logout')
		this.$store.commit('set', {activityTimer: false})
	},
	metaInfo: {
		title: 'Thanks',
	}
}
</script>